<template lang="pug">
  
div(fluid).pa-0
  
  v-container.justify-center.aling-center(style="margin-bottom: 3em; margin-top: 3em")
    v-row.justify-center.aling-center
      v-col( cols=12 md=5 )
        v-img(  :src='nosotros.ventana' height='100px' contain ).d-none.d-md-block
        v-img(  :src='nosotros.ventana' height='60px' contain ).d-md-none

        bloque_arbitros( :titulo='arbitraje_registro_de_árbitros.titulos.Nacionales' :arreglo='arbitraje_registro_de_árbitros.árbitros_Nacionales')
      //- linea separadora
      div.d-flex.flex-column.align-center
        .lineamedia_arbitraje( style='width:2px')
      v-col( cols=12 md=5 )
        v-img(  :src='nosotros.ventana' height='100px' contain ).d-none.d-md-block
        v-img(  :src='nosotros.ventana' height='60px' contain ).d-md-none

        bloque_arbitros( :titulo='arbitraje_registro_de_árbitros.titulos.Internacionales' :arreglo='arbitraje_registro_de_árbitros.árbitros_Extranjeros').mt-2
        
     

      

</template>
<script>
export default {
  components: {
    bloque_arbitros: ()=>import('./bloque_arbitros')
  }
}
</script>